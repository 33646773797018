import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './qualifications.css'

const Qualifications = (props) => {
  return (
    <div
      className={`qualifications-gallery3 thq-section-padding ${props.rootClassName} `}
    >
      <div className="qualifications-max-width thq-section-max-width">
        <div className="qualifications-content">
          <div className="qualifications-container1">
            <img
              alt="PlaceholderImage1302"
              src="/iims-logo-300x300-1-orig_orig-500h.jpg"
              className="qualifications-image11 thq-img-round"
            />
            <span>
              {props.image1Description ?? (
                <Fragment>
                  <span className="qualifications-text18 thq-body-small">
                    IIMS - International Institute of Marine Surveying / Full
                    Member
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="qualifications-container2">
            <img
              alt="PlaceholderImage1302"
              src="/marine-survey-associates-logo-pos1-orig_orig-500h.jpg"
              className="qualifications-image12 thq-img-ratio-16-9"
            />
            <span>
              {props.image1Description12 ?? (
                <Fragment>
                  <span className="qualifications-text19 thq-body-small">
                    MSA - Marine Survey Associates / Full Member
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="qualifications-container3">
            <img
              alt="PlaceholderImage1302"
              src="/ydsa-logo-6_orig-500h.png"
              className="qualifications-image13 thq-icon-medium thq-img-ratio-1-1"
            />
            <span>
              {props.image1Description11 ?? (
                <Fragment>
                  <span className="qualifications-text16 thq-body-small">
                    YDSA -Yacht Designers &amp; Surveyors Association /
                    Accredited Member
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="qualifications-container4">
            <img
              alt="PlaceholderImage1302"
              src="/rina-logo-3_orig-500h.png"
              className="qualifications-image14 thq-img-ratio-1-1"
            />
            <span>
              {props.image1Description10 ?? (
                <Fragment>
                  <span className="qualifications-text15 thq-body-small">
                    RINA - Royal Institution of Naval Architects / Associate
                    Member
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="qualifications-container5">
            <img
              alt="PlaceholderImage1302"
              src="/mecal-logo-6_orig-500h.png"
              className="qualifications-image15"
            />
            <span>
              {props.image1Description9 ?? (
                <Fragment>
                  <span className="qualifications-text17 thq-body-small">
                    International Marine Consultants / Grade A3 Surveyor
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Qualifications.defaultProps = {
  image1Description10: undefined,
  image1Description11: undefined,
  image1Description9: undefined,
  image1Description: undefined,
  rootClassName: '',
  image1Description12: undefined,
}

Qualifications.propTypes = {
  image1Description10: PropTypes.element,
  image1Description11: PropTypes.element,
  image1Description9: PropTypes.element,
  image1Description: PropTypes.element,
  rootClassName: PropTypes.string,
  image1Description12: PropTypes.element,
}

export default Qualifications
