import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Hero from '../components/hero'
import Gallery9 from '../components/gallery9'
import ContactForm from '../components/contact-form'
import Qualifications from '../components/qualifications'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Agitated Frozen Louse</title>
        <meta property="og:title" content="Agitated Frozen Louse" />
      </Helmet>
      <Hero
        button1={
          <Fragment>
            <span className="home-text10">Contact us</span>
          </Fragment>
        }
        heading2={
          <Fragment>
            <span className="home-text11">
              <span className="home-text12">
                Surveying
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text13">Yachts</span>
              <br className="home-text14"></br>
              <span className="home-text15">
                Securing
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text16">Journeys</span>
              <br className="BIG"></br>
            </span>
          </Fragment>
        }
        rootClassName="heroroot-class-name"
      ></Hero>
      <Gallery9
        text1={
          <Fragment>
            <span className="home-text18">
              Owner&apos;s Technical Representative
            </span>
          </Fragment>
        }
        text11={
          <Fragment>
            <span className="home-text19">
              <span className="home-text20">New Build Survey</span>
              <br></br>
            </span>
          </Fragment>
        }
        text12={
          <Fragment>
            <span className="home-text22">Insurance Surveys</span>
          </Fragment>
        }
        text13={
          <Fragment>
            <span className="home-text23">Pre-Purchase Survey</span>
          </Fragment>
        }
      ></Gallery9>
      <ContactForm
        email={
          <Fragment>
            <span className="home-text24 thq-body-small">
              info@yachtsurveyor.co.uk
            </span>
          </Fragment>
        }
        phone={
          <Fragment>
            <span className="home-text25 thq-body-small">
              <span>+44</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>7796392770</span>
            </span>
          </Fragment>
        }
        action={
          <Fragment>
            <span className="home-text29 thq-body-small">Submit</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text30 thq-heading-2">Contact us</span>
          </Fragment>
        }
        rootClassName="contact-formroot-class-name"
      ></ContactForm>
      <Qualifications
        rootClassName="qualificationsroot-class-name"
        image1Description={
          <Fragment>
            <span className="home-text31 thq-body-small">
              IIMS - International Institute of Marine Surveying / Full Member
            </span>
          </Fragment>
        }
        image1Description9={
          <Fragment>
            <span className="home-text32 thq-body-small">
              International Marine Consultants / Grade A3 Surveyor
            </span>
          </Fragment>
        }
        image1Description10={
          <Fragment>
            <span className="home-text33 thq-body-small">
              RINA - Royal Institution of Naval Architects / Associate Member
            </span>
          </Fragment>
        }
        image1Description11={
          <Fragment>
            <span className="home-text34 thq-body-small">
              YDSA -Yacht Designers &amp; Surveyors Association / Accredited
              Member
            </span>
          </Fragment>
        }
        image1Description12={
          <Fragment>
            <span className="home-text35 thq-body-small">
              MSA - Marine Survey Associates / Full Member
            </span>
          </Fragment>
        }
      ></Qualifications>
    </div>
  )
}

export default Home
